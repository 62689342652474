import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import "./Screen2.css";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import ListIcon from "@mui/icons-material/List";
import TextsmsIcon from "@mui/icons-material/Textsms";
import AddIcon from "@mui/icons-material/Add";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import CancelIcon from "@mui/icons-material/Cancel";
import ClearIcon from "@mui/icons-material/Clear";
import Cropper from "react-easy-crop";
import ImageIcon from "@mui/icons-material/Image";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import HomeIcon from "@mui/icons-material/Home";
import PaymentIcon from "@mui/icons-material/Payment";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import PaymentsIcon from "@mui/icons-material/Payments";
import axios, { AxiosError } from "axios";
import { Link } from "react-router-dom";
import { Transition } from "react-transition-group";
import { Drawer, Modal, message, Radio } from "antd";
import CropperModal from "../../cropper";
import GiftNote from "../../giftNote";
import { v4 as uuidv4 } from "uuid";
import countries from "../../../components/countries.json";
import App from "./Payment/App";
import apiRequest from "../../../apiRequest";

function preloadImage(url) {
  var img = new Image();
  img.src = url;
}
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const Screen2Frame = () => {
  const [upload, setupload] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectStyle, setselectStyle] = useState(false);
  const [selectSize, setselectSize] = useState(false);
  const [addAddressPopup, setaddAddressPopup] = useState(false);
  const [addPaymentMethodPopup, setaddPaymentMethodPopup] = useState(false);
  const [creditCardMethod, setcreditCardMethod] = useState(false);
  const [allFrames, setallFrames] = useState([]);
  const [allFrameSizes, setallFrameSizes] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [showGiftNote, setGiftNoteModal] = useState(false);
  const [giftNote, setGiftNote] = useState("");
  const [visible, setVisible] = useState(false);
  const [promoCode, setPromoCode] = useState(false);
  const [cropModal, setCropModal] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState({});
  const [selectedSize, setSelectedSize] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [stateAddress, setStateAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [promoOrGiftCard, setPromoOrGiftCard] = useState("");
  const [promoCodeInp, setpromoCodeInp] = useState("");
  const [giftCardInp, setGiftCardInp] = useState("");
  const [code, setCode] = useState({});
  const [codeLoading, setCodeLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    const userEmail = localStorage.getItem("userEmail");
    setName(userName);
    setEmail(userEmail);
  }, []);

  const setAddressInfoFunction = () => {
    if (
      !name ||
      !email ||
      !address ||
      !city ||
      !stateAddress ||
      !zipCode ||
      !country
    ) {
      Modal.error({
        title: "Incomplete Address",
        content: "Please fill in all the fields",
      });
      return;
    }

    setaddAddressPopup(false);
  };

  const frameSizeValidatin = () => {
    if (
      selectedSize &&
      Object.keys(selectedSize).length === 0 &&
      Object.getPrototypeOf(selectedSize) === Object.prototype
    ) {
      messageApi.open({
        type: "error",
        content: "Please Select a frame Size!!!",
        duration: 3,
      });
    } else {
      setVisible(!visible);
    }
  };

  // =============================== Getting all frames api ======================================

  useEffect(() => {
    apiRequest({
      method: "get",
      url: `/frame/get/active`,
      headers: {
        "x-auth-token": localStorage.getItem("jwt-Token"),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        setallFrames(data.frames);
        if (data.frames && data.frames.length > 0) {
          data.frames.map((item) => preloadImage(item.frame));
          setSelectedFrame(data?.frames[0]);
          setallFrameSizes([
            {
              name: "Small",
              frame: data.frames[0]?.frame,
              ...data?.frames[0]?.variant?.small,
            },
            {
              name: "Medium",
              frame: data.frames[0]?.frame,
              ...data?.frames[0]?.variant?.medium,
            },
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleImgRemove = (parameter) => {
    const rem = selectedImage.filter((item) => item.id !== parameter);
    setSelectedImage(rem);
  };

  // ===========================frame with key ==================================================

  const hello = (key) => {
    return (
      <>
        <img style={{ width: "70px", height: "70px" }} src={key} />
      </>
    );
  };

  //===============================update gift card Validity ===========================
  const updateGiftCardValidity = async () => {
    return await apiRequest({
      method: "PATCH",
      url: `giftCard/setValidityFalse`,

      data: {
        giftCardCodeID: code?._id,
      },
    })
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //===============================get total ===========================================
  const getTotal = () => {
    let total = 0;
    total = selectedImage.length * selectedSize?.price;
    if (selectedFrame?.pricing?.chargeTax) {
      total = total + selectedFrame?.pricing?.costPerItem;
    }
    if (code && Object.keys(code).length !== 0) {
      total = total - code?.amount;
      if (total < 0) {
        total = 0;
      }
    }

    return total;
  };

  //===============================Order Place API =====================================
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const placeorderfunction = async () => {
    const data = new FormData();
    selectedImage.map((item) => {
      data.append("rawImage", item.image);
    });
    selectedImage.map((item, index) => {
      let newFile = dataURLtoFile(item.image, `mainImage${index}.png`);
      data.append("rawImage", newFile);
    });

    selectedImage.map((item, index) => {
      if (item.croppedImage) {
        let newFile = dataURLtoFile(item.croppedImage, `crop${index}.png`);
        data.append("croppedImage", newFile);
      } else {
        let newFile = dataURLtoFile(item.image, `mainImageCropped${index}.png`);
        data.append("croppedImage", newFile);
      }
    });

    data.append("frameSelected", selectedFrame._id);
    data.append("sizeSelected", selectedSize?.name?.toLowerCase());
    data.append("fullName", name);
    data.append("email", email);
    data.append("giftNote", giftNote);

    data.append("shippingAddress[address]", address);
    data.append("shippingAddress[address2]", address2);
    data.append("shippingAddress[city]", city);
    data.append("shippingAddress[zipCode]", zipCode);
    data.append("shippingAddress[state]", stateAddress);
    data.append("shippingAddress[country]", country);
    data.append("total", getTotal());

    return await apiRequest({
      method: "post",
      url: `/order`,
      headers: {
        "Content-Type": " multipart/form-data",
        "x-auth-token": localStorage.getItem("jwt-Token"),
      },
      data,
    })
      .then((res) => {
        if (code && Object.keys(code).length !== 0) {
          updateGiftCardValidity();
        }
        setPlaceOrderLoading(false);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ================================== Make Payment API=========================================
  const makePayment = async (order_id) => {
    axios({
      method: "post",
      url: `https://backend.pictings.com/payment`,

      data: {
        line_items: [
          {
            price_data: {
              currency: "usd",
              product_data: {
                name: "Pay to confirm your order on Pictings",
              },
              unit_amount: (getTotal() / selectedImage?.length) * 100,
            },
            quantity: selectedImage?.length,
          },
        ],
        metadata: {
          discountCode: promoCode.code,
          order_id: order_id,
        },
        success_url: `${window.location.origin}/checkout`,
        cancel_url: `${window.location.origin}`,
      },
    })
      .then((response) => {
        setPlaceOrderLoading(false);
        window.location.href = response.data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //===============================Order Place Function =====================================
  const placingOrder = async () => {
    if (
      !name ||
      !email ||
      !address ||
      !city ||
      !stateAddress ||
      !zipCode ||
      !country
    ) {
      Modal.error({
        title: "Incomplete Address",
        content: "Please fill in all the fields",
      });
      return;
    } else {
      setPlaceOrderLoading(true);

      let order = await placeorderfunction();
      let order_id = order?.order?._id;

      if (getTotal() === 0) {
        window.location = `${window.location.origin}/checkout`;
      } else {
        await makePayment(order_id);
      }
    }
  };

  // ==============================Get PromoCode =======================================
  const checkGiftCard = () => {
    if (!giftCardInp) {
      Modal.error({
        title: "Invalid Gift Card code",
        content: "Please enter a valid Gift Card Code",
      });
      return;
    }
    setCodeLoading(true);
    apiRequest({
      method: `GET`,
      url: `giftCard/getByName/${giftCardInp}`,
    })
      .then((res) => {
        const giftCardData = res?.data;
        if (giftCardData !== null) {
          if (giftCardData?.isValid) {
            setCode(giftCardData);
          } else {
            Modal.error({
              title: "Invalid Gift Card code",
              content: "Please enter a valid Gift Card Code",
            });
          }
        } else {
          Modal.error({
            title: "Invalid Gift Card code",
            content: "Please enter a valid Gift Card Code",
          });
        }

        setCodeLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkoutDrawer = () => (
    <Drawer
      title='Basic Drawer'
      placement='right'
      onClose={onClose}
      visible={visible}
      width={440}>
      <div className='container  p-3'>
        <h2 className='fw-bolder mt-3 mx-3'>Checkout</h2>
        <div className='check_out_list_parent'>
          <ul className='list_check_out'>
            <li
              className='mb-4 global_hover'
              onClick={() => setaddAddressPopup(true)}
              style={{
                whiteSpace: "nowrap",
                width: "350px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}>
              {" "}
              <HomeIcon
                className='mb-1 mx-2'
                fontSize='medium'
                style={{ color: "#337CEA" }}
              />{" "}
              {name ? (
                <span style={{ color: "#337CEA" }} className='   fw-bold'>
                  {name} {address ? `, ${address}` : ""}
                </span>
              ) : (
                <span style={{ color: "#337CEA" }} className='fw-bold'>
                  Add Address
                </span>
              )}
            </li>
            <div className='mx-4'></div>

            <li
              className='mb-1 text-dark global_hover '
              //  onClick={getGiftCard}
            >
              <CardGiftcardIcon
                style={{ color: "#337CEA" }}
                className='mb-1 mx-2'
                fontSize='medium'
              />

              <span
                onClick={() => setGiftNoteModal(true)}
                style={{ color: "#337CEA" }}
                className='fw-bold'>
                {giftNote ? "Gift Note Added" : "Add Gift Note"}
              </span>
            </li>
          </ul>
        </div>
        <hr />
        <div className='p-1'>
          {code && Object.keys(code).length === 0 ? (
            <div className='d-flex align-items-center justify-content-start'>
              <input
                className='px-2 py-1 border border-primary rounded border-1 w-50'
                placeholder='Type gift card code'
                value={giftCardInp}
                onChange={(e) => setGiftCardInp(e.target.value)}
              />
              <span>
                <button
                  onClick={checkGiftCard}
                  className='btn btn-primary mx-3  '>
                  {codeLoading ? "adding..." : " Add Gift Card"}
                </button>
              </span>
            </div>
          ) : (
            <div className='mb-4 row mx-1'>
              <div className='col-2 text-primary mt-1'>
                <CardGiftcardIcon className='mb-1 mx-2 fs-1 ' />
              </div>
              <div className='col-8'>
                <p className='m-0 p-0'>{code?.code}</p>
                <p className='text-primary m-0 p-0'>
                  <div> $ {code?.amount}</div>
                </p>
              </div>
              <p
                className='col-2 mt-2 text-muted global_hover '
                style={{ textDecoration: "underline" }}
                onClick={() => setCode({})}>
                cancel
              </p>
            </div>
          )}

          <hr />
          <div className='px-2 orderDetail'>
            <div className='d-flex justify-content-between'>
              <p>Frame Price</p>
              <p>${selectedSize?.price}</p>
            </div>
            <div className='d-flex justify-content-between'>
              <p>
                {selectedImage?.length}{" "}
                {selectedImage?.length == 1 ? "tile" : "tiles"}{" "}
                {selectedSize ? `, ${selectedSize?.name}` : ""}
              </p>

              <p>${selectedImage.length * selectedSize?.price}</p>
            </div>

            {selectedFrame?.pricing?.chargeTax && (
              <div className='d-flex justify-content-between'>
                <p>Tax</p>
                <p>${selectedFrame?.pricing?.costPerItem}</p>
              </div>
            )}

            <div className='d-flex justify-content-between'>
              <p>Shipping</p>
              <p>Free</p>
            </div>

            {code && Object.keys(code).length !== 0 ? (
              <div className='d-flex justify-content-between'>
                <p>Gift Card Discount</p>
                <p>${code?.amount}</p>
              </div>
            ) : (
              ""
            )}
            <div className='d-flex justify-content-between fw-bolder'>
              <p>Total</p>
              <p>${getTotal()}</p>
            </div>
          </div>
          <div className='p-3'>
            <div>
              <button
                className='btn btn-primary w-100  btn-lg p-2'
                onClick={() => {
                  placingOrder();
                }}>
                {placeOrderLoading ? (
                  <div
                    className='d-flex align-items-center justify-content-center '
                    style={{ gap: "1rem" }}>
                    <Loading3QuartersOutlined spin />{" "}
                    <p style={{ margin: "0" }}>Processing...</p>
                  </div>
                ) : (
                  "Place Order"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );

  const returnSizeHeight = (size) => {
    size = size * 96;
    size /= 2;
    return ((100 * size) / window.innerHeight) * 0.8;
  };

  const returnSizeWidth = (size) => {
    size = size * 96;
    size /= 2;
    return ((100 * size) / window.innerWidth) * 0.8;
  };

  return (
    <>
      {contextHolder}
      <div>
        <GiftNote
          isModalVisible={showGiftNote}
          setIsModalVisible={setGiftNoteModal}
          giftNote={giftNote}
          setGiftNote={setGiftNote}
        />
        {cropModal ? (
          <CropperModal
            isModalVisible={cropModal}
            setIsModalVisible={(val) => {
              if (val) {
                setSelectedImage(
                  selectedImage.map((item) => {
                    return {
                      ...item,
                      croppedImage:
                        cropModal.id == item.id ? val.image : item.croppedImage,
                      zoom: val.zoom,
                      position: val.position,
                    };
                  })
                );
              }
              setCropModal(false);
            }}
            selectedSize={selectedSize}
            selectedFrame={selectedFrame}
          />
        ) : (
          ""
        )}
        <div className='bg-light d-flex justify-content-between align-items-center screen2_navbar '>
          <div></div>
          <div>
            <h4 className='mb-0'>
              <img src={"/logo.png"} style={{ width: "115px" }} />
            </h4>
          </div>
          <div></div>
        </div>

        {selectedImage.length === 0 && (
          <div className='addBtnContainer'>
            <div
              className='screen_2_body global_hover addBtn'
              onClick={() => {
                setupload(!upload);
              }}>
              <div className='screen_2_body_content'>
                <div>
                  <AddIcon
                    className='text-primary '
                    style={{ fontSize: "100px" }}
                  />
                </div>
              </div>
            </div>
            <h5 className='text-muted'>Pick some photos to get started</h5>
          </div>
        )}

        {selectedImage.length !== 0 && (
          <div
            className='container-fluid px-5 imagesContainer'
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "20px",
              // marginTop: "3%",
              paddingBottom: "200px",
              overflow: "auto",
              height: "83vh",
            }}>
            {selectedImage.map((item, i) => (
              <div className='screen_2_body2 global_hover ' key={i}>
                <div className='screen_2_body2_content'>
                  <button
                    className='btn-remove'
                    onClick={() => handleImgRemove(item.id)}>
                    <CancelIcon />
                  </button>
                  <div
                    onClick={() => {
                      setCropModal(item);
                    }}
                    className='align_frame_centerr'
                    style={{
                      width: `${returnSizeWidth(
                        selectedSize?.size?.split("*")[0].trim()
                      )}vw`,
                      height: `${returnSizeHeight(
                        selectedSize?.size?.split("*")[1].trim()
                      )}vh`,
                    }}>
                    {selectedFrame ? (
                      <img
                        className='frameContainer'
                        src={selectedFrame.frame}
                      />
                    ) : (
                      ""
                    )}
                    <img
                      alt='please select the image'
                      className='mainImage'
                      src={item.croppedImage ? item.croppedImage : item.image}
                      // style={{ transform: item.zoom ? item.zoom : "none" }}
                    />{" "}
                  </div>
                </div>

                <div
                  style={{
                    bottom: "-85px",
                    position: "absolute",
                    textAlign: "center",
                  }}></div>
              </div>
            ))}
          </div>
        )}

        {selectedImage.length !== 0 && (
          <div>
            <button className='add_more_imgs' style={{ cursor: "pointer" }}>
              <AddIcon style={{ fontSize: "100px", color: "#337CEA" }} />
              <input
                type='file'
                className='custom-file-input2'
                name='img'
                style={{ cursor: "pointer" }}
                onChange={async (event) => {
                  if (event.target.files)
                    setSelectedImage([
                      ...selectedImage,
                      {
                        image: await toBase64(event.target.files[0]),
                        frame: "",
                        id: uuidv4(),
                      },
                    ]);
                }}
              />
            </button>
          </div>
        )}

        {selectedImage.length === 0 && upload && (
          <div className='screen2_upload_section'>
            <div className='list-group  list_style'>
              <label className='list-group-item text-center'>
                <CameraAltIcon
                  className='text-primary text-center mx-2 my-2'
                  fontSize='large'
                />
                <span>
                  <input
                    type='file'
                    className='custom-file-input  '
                    onChange={async (event) => {
                      setSelectedImage([
                        ...selectedImage,
                        {
                          image: await toBase64(event.target.files[0]),
                          frame: "",
                          id: uuidv4(),
                        },
                      ]);
                    }}
                  />
                </span>
              </label>
            </div>
          </div>
        )}

        {/* =========================================Chunk============================================= */}
        {/* Select style btn */}
        {selectedImage.length !== 0 && upload && (
          <div className='screen_2_editing_section d-flex justify-content-between'>
            <div className='dropdown' onClick={() => setselectStyle(true)}>
              <button
                className='btn  dropdown-toggle'
                type='button'
                style={{ backgroundColor: "#FEFFFF" }}>
                <ImageIcon fontSize='medium' />{" "}
                <span className='mx-2 fw-bold'>
                  {selectedFrame?.frameTitle}
                </span>
              </button>
            </div>
            <div className='dropdown'>
              <button
                className='btn  dropdown-toggle'
                type='button'
                style={{ backgroundColor: "#FEFFFF" }}
                onClick={() => setselectSize(true)}>
                <AspectRatioIcon fontSize='medium' />{" "}
                <span className='mx-2 fw-bold'>{selectedSize?.name}</span>
              </button>
            </div>
          </div>
        )}

        {/* =================================Select size div==================================== */}
        {selectStyle && (
          <div
            className='screen_2_select_filter_div container'
            style={{ position: "fixed", zIndex: 9999999 }}>
            <div className='d-flex justify-content-between mt-3 mx-3'>
              <h6>Select Style</h6>
              <h6
                className='text-primary global_hover'
                onClick={() => setselectStyle(false)}>
                Done
              </h6>
            </div>
            <div className='d-flex scroll_x '>
              {allFrames?.map((item) => (
                <div
                  className='text-center mt-3 mb-4 mx-4'
                  style={{ minWidth: "70px" }}>
                  <div
                    className='img_filter global_hover mb-1'
                    onClick={() => {
                      setSelectedFrame(item);
                      setallFrameSizes([
                        {
                          name: "Small",
                          frame: selectedFrame?.frame,
                          ...item?.variant?.small,
                        },
                        {
                          name: "Medium",
                          frame: selectedFrame?.frame,
                          ...item?.variant?.medium,
                        },
                      ]);
                    }}>
                    <img
                      style={{ width: "70px", height: "70px" }}
                      src={item.frame}
                    />
                  </div>
                  <div
                    className='fw-bold'
                    style={{ textTransform: "capitalize" }}>
                    {item.frameTitle}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {selectSize && (
          <div
            className='screen_2_select_size_div container '
            style={{ position: "fixed", zIndex: 9999999 }}>
            <div className='d-flex justify-content-between mt-3 mx-3'>
              <h6>Select Size</h6>
              <h6
                className='text-primary global_hover'
                onClick={() => setselectSize(false)}>
                Done
              </h6>
            </div>

            <div className='d-flex scroll_x'>
              {allFrameSizes?.map((data) => {
                return (
                  <div
                    className={`card mt-3 mb-3 global_hover mx-2`}
                    onClick={() => setSelectedSize(data)}
                    style={{
                      minWidth: "10rem",
                      borderRadius: "0px",
                      border:
                        selectedSize?.name == data.name
                          ? "2px solid #337CEA"
                          : "2px solid #808080",
                      color: selectedSize?.name == data.name ? "#337CEA" : "",
                    }}>
                    <img
                      style={{
                        height: data.name === "Small" ? "190px" : "210px",
                      }}
                      src={data.frame}
                      alt='Card image cap'
                    />
                    <div
                      className='card-body'
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                      }}>
                      <p
                        className='card-text fw-bold'
                        style={{ margin: "0", padding: "0" }}>
                        {data.name}
                      </p>
                      <p
                        className='card-text'
                        style={{ margin: "0", padding: "0" }}>
                        ${data.price} each
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {/* Check out bar=============================================================== */}
        {selectedImage.length !== 0 && (
          <div className='checkOut_bar text-center'>
            <button
              className='checkOut_btn mt-3'
              onClick={() => frameSizeValidatin()}>
              <div>Checkout</div>
            </button>
          </div>
        )}

        {/* =====================Add payment method popup================================= */}
        {addPaymentMethodPopup && (
          <div>
            <div className='add_payment_popup_parent'>
              <p className='global_hover'>
                <CancelIcon
                  style={{
                    position: "absolute",
                    marginTop: "-18px",
                    marginLeft: "-18px",
                  }}
                  onClick={() => setaddPaymentMethodPopup(false)}
                />{" "}
              </p>
              <div
                className='m-4 global_hover'
                // onClick={() => setcreditCardMethod(true)}
                // onClick={() => makePayment()}
              >
                <p className='fw-bold'>
                  <AddIcon className='mx-3' /> Add Credit Card
                </p>
              </div>
              <hr />
              <div className='m-4 global_hover'>
                <p className='fw-bold'>
                  <PaymentsIcon className='mx-3' />
                  Use Paypal
                </p>
              </div>
            </div>
          </div>
        )}

        {creditCardMethod && (
          <div className='credit_card_popup_parent'>
            <div className='d-flex justify-content-between container px-3 mt-3 fw-bolder'>
              <div></div>
              <div>Card Details</div>
              <div
                className='text-primary global_hover'
                onClick={() =>
                  setcreditCardMethod(false) & setaddPaymentMethodPopup(false)
                }>
                Done
              </div>
            </div>
            <hr />

            <div>
              <App />
            </div>
          </div>
        )}
      </div>
      {/* ==============================Add Address Popup ====================================== */}
      {addAddressPopup && (
        <div className='add_address_popup'>
          <div className='d-flex justify-content-between container px-3 mt-3 fw-bolder'>
            <div
              className='text-muted global_hover'
              onClick={() => setaddAddressPopup(false)}>
              <ClearIcon />
            </div>
            <div>Add Address</div>

            <div
              className='text-primary global_hover'
              onClick={() => setAddressInfoFunction()}>
              Done
            </div>
          </div>
          <hr />
          <div className='mx-2 mx-sm-5  address_form px-4'>
            <div>
              <span>EMAIL: </span>
              <input
                className=''
                name='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <span>FULL NAME: </span>
              <input
                className=''
                name='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div>
              <span>ADDRESS: </span>
              <input
                className=''
                name='address'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div>
              <span>ADDRESS 2: </span>
              <input
                className=''
                placeholder='(Optional)'
                name='address2'
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
              />
            </div>
            <div>
              <span>CITY: </span>
              <input
                className=''
                name='city'
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div>
              <span>STATE: </span>
              <input
                className=''
                name='state'
                value={stateAddress}
                onChange={(e) => setStateAddress(e.target.value)}
              />
            </div>
            <div>
              <span>ZIP CODE: </span>
              <input
                className=''
                type='number'
                min='0'
                name='zipCode'
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>
            <div>
              <span>COUNTRY:</span>
              <select
                defaultValue={country}
                className='countries'
                name='country'
                onChange={(e) => setCountry(e.target.value)}>
                <option value=''>--select country--</option>
                {countries?.map(({ name }) => {
                  return (
                    <option value={name} key={name}>
                      {name}
                    </option>
                  );
                })}
              </select>
              {/* <input
                className=''
                name='country'
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              /> */}
            </div>
          </div>
        </div>
      )}
      {checkoutDrawer()}
    </>
  );
};

export default Screen2Frame;
