export const increment = () => {
    return {
        type: 'INCREMENT'
    }
}
export const decrement = () => {
    return {
        type: 'DECREMENT'
    }
}
export const initialVal = () => {
    return {
        type: 'INITIAL'
    }
}

