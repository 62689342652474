import { Modal } from "antd";
import styled from "styled-components";

export const EditImg = styled.div`
  text-align: center;
`;
export const EditModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      left: 0 !important;
    }
    .ant-modal-header {
      text-align: center !important;
      border-bottom: none !important;
    }
    .ant-modal-body {
      padding-top: 0 !important;
      text-align: center !important;
      p {
        color: #4f4f4f;
      }
    }
    .zoomSlider {
      display: flex !important;
      justify-content: center;
      align-items: center;

      .slide {
        width: 200px;

        .ant-slider-handle {
          width: 23px !important;
          height: 23px !important;
          margin-top: -10px;
        }
        .ant-tooltip-open {
          //   display: none !important;
        }
      }
      span {
        font-size: 22px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 0.2rem;
        margin: 0.5rem;
        border: 0.5px solid #4e4e4e;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .ant-modal-footer {
      display: none;
    }
  }
  .doneBtn {
    background-color: #1976d2;
    color: #ffffff;
    border: none;
    width: 200px;
    padding: 0.5rem;
  }
`;
