import { Form, Input } from "antd";
import React, { useState, useEffect } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import Header from "../../header/Index";
import { GrCheckmark } from "react-icons/gr";
import { AiOutlineTag, AiOutlineGift } from "react-icons/ai";
import { FaRegEnvelope } from "react-icons/fa";
import { Modal } from "antd";
import {
  GiftCardContainer,
  GiftCards,
  GiftCardWrapper,
  TileBtn,
  Amount,
  CardDesc,
  BuyBTn,
  WorkList,
} from "./index.style";
import FooterNav from "../../footerNav/Index";
import apiRequest from "../../../apiRequest";
import axios from "axios";

const GiftCard = () => {
  const [email, setEmail] = useState("");
  const [selectedCard, setSelectedCard] = useState("");
  const [sliderImage, setSliderImage] = useState("");
  const [giftCardAmount, setGiftCardAmount] = useState(0);
  const [cards, setCards] = useState([]);
  const getCards = () => {};

  useEffect(() => {
    apiRequest({
      method: "get",
      url: "/giftCard",
      headers: {
        "x-auth-token": localStorage.getItem("jwt-Token"),
      },
    }).then((res) => {
      setCards(res.data.giftCard);
    });
  }, []);

  const makePayment = async () => {
    if (!selectedCard) {
      Modal.error({
        title: "Incomplete Action",
        content: "Please select a gift card",
      });
      return;
    }

    if (!email) {
      Modal.error({
        title: "Invalid",
        content: "Please enter a valid email",
      });
      return;
    }

    if (giftCardAmount === 0) {
      Modal.error({
        title: "Invalid",
        content: "Please select gift card Amount",
      });
      return;
    }

    axios({
      method: "post",
      url: `https://backend.pictings.com/payment`,

      data: {
        line_items: [
          {
            price_data: {
              currency: "usd",
              product_data: {
                name: "Pay to confirm your giftcard on pictings",
              },
              unit_amount: Number(giftCardAmount) * 100,
            },
            quantity: 1,
          },
        ],
        metadata: {
          giftCardID: selectedCard._id,
          email: email,
          amount: Number(giftCardAmount),
        },
        success_url: `${window.location.origin}`,
        cancel_url: `${window.location.origin}/giftcard`,
      },
    })
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <GiftCardContainer>
      <Header />
      <Container>
        <Row>
          <h1 className='mainHeading'>Pictings Gift Card </h1>
          <Col md={6}>
            <GiftCards>
              <div>
                <img src={sliderImage || "/images/card.png"} alt='' />
              </div>

              <div className='giftCarSlider'>
                {selectedCard?.media?.length > 1
                  ? selectedCard?.media?.map((img, i) => {
                      return (
                        <img
                          className='giftCarSliderImg'
                          key={i}
                          src={img}
                          alt={img}
                          onClick={() => {
                            setSliderImage(img);
                          }}
                        />
                      );
                    })
                  : ""}
              </div>

              <p>Delivered by email</p>
            </GiftCards>
          </Col>
          <Col md={6}>
            <CardDesc>
              <h1>Pictings Gift Card </h1>
              <Form layout='vertical'>
                <Form.Item label='Who is the gift for:' name='email'>
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email'
                  />
                </Form.Item>
              </Form>
              <p>Select number of tiles</p>
              <h6>Note: Gift card tiles are always 8"x8".</h6>
              <div className='giftCardsWrapper'>
                {cards?.length > 0 ? (
                  cards.map((item) => (
                    <TileBtn
                      key={item._id}
                      className={selectedCard?._id == item._id ? "active" : ""}
                      onClick={() => {
                        setSliderImage(item?.media?.[0]);
                        setSelectedCard(item);
                      }}>
                      <strong>{item.giftCardTitle}</strong>
                    </TileBtn>
                  ))
                ) : (
                  <span>No Gift Card Yet!</span>
                )}
              </div>
              <Amount onChange={(e) => setGiftCardAmount(e.target.value)}>
                <option value=''>Select Amount</option>
                {selectedCard?.denominations?.map((d, i) => {
                  return (
                    <option value={d} key={i}>
                      $ {d}
                    </option>
                  );
                })}
              </Amount>
              <h6>
                <GrCheckmark /> <span>Shipping Included</span>
              </h6>
              <BuyBTn onClick={() => makePayment()}>Buy</BuyBTn>
              <h4>How does it work?</h4>
              <div>
                <WorkList>
                  <span>
                    <AiOutlineTag />
                  </span>
                  <div>1. Buy the digital gift card</div>
                </WorkList>
                <WorkList>
                  <span>
                    <FaRegEnvelope />
                  </span>
                  <div>
                    2. We send it over email to your friend or family member
                  </div>
                </WorkList>
                <WorkList>
                  <span>
                    <AiOutlineGift />
                  </span>
                  <div>
                    3. They order Pictings and apply the gift code at checkout!
                  </div>
                </WorkList>
                <p style={{ marginLeft: "20px", marginTop: "20px" }}>
                  Any questions? <a href=''>Contact us</a>
                </p>
              </div>
            </CardDesc>
          </Col>
        </Row>
      </Container>
      {/* <FooterNav /> */}
    </GiftCardContainer>
  );
};

export default GiftCard;
