import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { HeaderNav } from "./index.style";

const Header = () => {
  return (
    <HeaderNav bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="" className="logolink">
          <img src="/images/pictings.jpeg" alt="" />
        </Navbar.Brand>
      </Container>
    </HeaderNav>
  );
};

export default Header;
