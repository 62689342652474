import React from "react";
import "./screen1.css";
import Joi from "joi";
import { useValidator } from "react-joi";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { guestName, guestEmail } from "../../../actions/guestInfoAction";
import { increment, decrement, initialVal } from "../../../actions/navAction";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Screen1 = () => {
  const [div1, setdiv1] = useState(true);
  const [div2, setdiv2] = useState(false);
  const [div3, setdiv3] = useState(false);
  const guest = useSelector((state) => state.guestInfoReducer);
  const [guestsInfo, setguestsInfo] = useState(guest);

  guest.name = guestsInfo.name;
  guest.email = guestsInfo.email;

  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.name == "name") {
      localStorage.setItem("userName", e.target.value);
    }

    if (e.target.name == "email") {
      localStorage.setItem("userEmail", e.target.value);
    }
    setguestsInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const counter = useSelector((state) => state.counterReducer);

  return (
    <div className=''>
      <h2
        style={{
          position: "absolute",
          top: "2%",
          left: "2%",
          cursor: "pointer",
        }}>
        {counter === 1 ? (
          <div>
            <ArrowBackIosNewIcon className='text-muted' />
          </div>
        ) : (
          <div></div>
        )}
        {counter === 2 ? (
          <div>
            <ArrowBackIosNewIcon
              className='text-light'
              onClick={() =>
                setdiv1(true) &
                setdiv2(false) &
                setdiv3(false) &
                dispatch(initialVal())
              }
            />
          </div>
        ) : (
          <div></div>
        )}
        {counter === 3 ? (
          <div>
            <ArrowBackIosNewIcon
              className='text-light'
              onClick={() =>
                setdiv2(true) & setdiv3(false) & dispatch(decrement())
              }
            />
          </div>
        ) : (
          <div></div>
        )}
      </h2>
      <div className='screen1_parent_div'></div>
      {/* -------------------------------------Chunk1----------------------------------------- */}

      {div1 && (
        <div className='text-center who_for_parent'>
          <p className='text-light fw-ligth display-6'>Who's it for?</p>
          <div className='containerr'>
            <div
              className='left-div global_hover'
              onClick={() =>
                setdiv1(false) & setdiv2(true) & dispatch(increment())
              }>
              <div
                className=' p-4 m-4 text-center outer_container_card'
                style={{ float: "right" }}>
                <div className='inner_card_div'></div>

                <h4 style={{ color: "#1976d2" }} className='mt-3'>
                  <span>For myself</span>
                </h4>

                {/* <p className="text-muted ">Lorem ipsum dolor, sit</p> */}
              </div>
            </div>
            <div
              className='right-div global_hover'
              onClick={() =>
                setdiv1(false) & setdiv2(true) & dispatch(increment())
              }>
              <div className=' p-4 m-4 text-center outer_container_card'>
                <div className='inner_card_div'></div>

                <h4 style={{ color: "#1976d2" }} className='mt-3'>
                  <span>For someone else</span>
                </h4>

                {/* <p className="text-muted ">Amit concepeto</p> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* //-------------------------------------Chunk2----------------------------------------- */}
      {div2 && (
        <div className='text-center who_for_parent '>
          <div className='screen_1_lets_get_to_know_parent p-4'>
            <h2 className='pt-5 '>
              <span className='fw-300 '>Let's get to know you</span>
            </h2>
            <input
              className='form-control form-control-lg global_input mt-4'
              type='text'
              onChange={handleChange}
              name='name'
              placeholder="What's your name?"
            />
            {guestsInfo.name.length >= 3 && (
              <button
                className='global_button mt-3'
                onClick={() =>
                  setdiv2(false) & setdiv3(true) & dispatch(increment())
                }>
                Continue
              </button>
            )}
            {guestsInfo.name.length < 3 && (
              <button className='global_button_disabled mt-3' disabled>
                Continue
              </button>
            )}
          </div>
        </div>
      )}
      {/* //-------------------------------------Chunk3----------------------------------------- */}

      {div3 && (
        <div className='text-center who_for_parent '>
          <div className='screen_1_lets_get_to_know_parent p-4'>
            <h2 className='pt-5 '>
              <span className='fw-300 '>Hey there!</span>
            </h2>
            <input
              className='form-control form-control-lg global_input mt-4'
              type='email'
              onChange={handleChange}
              pattern='.+@globex\.com'
              name='email'
              placeholder="What's your email?"
            />
            {guestsInfo.email.length >= 3 && (
              <Link to='/upload'>
                <button className='global_button mt-3'>Continue</button>
              </Link>
            )}
            {guestsInfo.email.length < 3 && (
              <button className='global_button_disabled mt-3'>Continue</button>
            )}
            <p className='mt-3 text-muted p-0 term_policies_dis'>
              By continuing you indicate that you have read and agreed to our
            </p>
            <p className='term_policies'>Terms of Uses & Privacy Policy</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Screen1;
