import React, { useState, useCallback, useEffect, useRef } from "react";
import { EditImg, EditModal } from "./index.style";
import { Slider } from "antd";
import { GrFormSubtract } from "react-icons/gr";
import { AiOutlinePlus } from "react-icons/ai";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AvatarEditor from "react-avatar-editor";
import domtoimage from "dom-to-image";

const EditPhoto = ({
  isModalVisible,
  setIsModalVisible,
  selectedSize,
  selectedFrame,
}) => {
  const [zoom, setZoom] = useState(1);
  const editor = useRef(null);
  const [coords, setCoords] = useState({});
  const [position, setPosition] = useState({});

  useEffect(() => {
    var tempCo = document.querySelector(".modalWindow .align_frame_centerr");
    if (tempCo) tempCo = tempCo.getBoundingClientRect();
    setCoords(tempCo);
    if (isModalVisible) {
      setZoom(isModalVisible.zoom ? isModalVisible.zoom : 1);
      setPosition(
        isModalVisible.position ? isModalVisible.position : { x: 0, y: 0 }
      );
    }
  }, [isModalVisible]);

  const handleOk = () => {
    const canvas = editor.current.getImage();

    setIsModalVisible({ image: canvas.toDataURL(), zoom, position });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    // setZoom(1);
  };

  const returnSizeHeight = (size) => {
    size = size * 96;
    size /= 2;
    return ((100 * size) / window.innerHeight) * 0.8;
  };

  const returnSizeWidth = (size) => {
    size = size * 96;
    size /= 2;
    return ((100 * size) / window.innerWidth) * 0.8;
  };

  return (
    <EditImg>
      <EditModal
        title='Edit Photo'
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        className='cropModal'>
        <div className=''>
          <div className='d-flex justify-content-center modalWindow '>
            <div
              className='my-4 align_frame_centerr'
              style={{
                width: `${returnSizeWidth(
                  selectedSize?.size?.split("*")[0].trim()
                )}vw`,
                height: `${returnSizeHeight(
                  selectedSize?.size?.split("*")[1].trim()
                )}vh`,
              }}>
              {selectedFrame ? (
                <img
                  style={{ zIndex: 1 }}
                  className='frameContainer'
                  src={selectedFrame.frame}
                />
              ) : (
                ""
              )}
              <AvatarEditor
                ref={editor}
                image={isModalVisible?.image}
                width={coords?.width}
                height={coords?.height}
                scale={zoom}
                border={0}
                position={position}
                className='mainImage'
                onPositionChange={(val) => {
                  setPosition(val);
                }}
              />
            </div>
          </div>

          <div className='zoomSlider' style={{ margin: "15px 0" }}>
            <span style={{ opacity: zoom >= 0 ? 1 : 0.5 }}>
              <GrFormSubtract
                onClick={() => {
                  if (zoom >= 0) setZoom(zoom - 0.1);
                }}
              />
            </span>

            <div className='slide'>
              <Slider
                min={1}
                defaultValue={zoom}
                max={3}
                step={0.1}
                onChange={(val) => {
                  setZoom(val);
                }}
                value={zoom}
              />
            </div>
            <span>
              <AiOutlinePlus
                onClick={() => {
                  if (zoom <= 3) setZoom(zoom + 0.1);
                }}
              />
            </span>
          </div>
          <h6 style={{ userSelect: "none" }} className='mt-3'>
            SCROLL TO ZOOM, DRAG TO MOVE
          </h6>

          <button className='doneBtn' onClick={handleOk}>
            Done
          </button>
        </div>
      </EditModal>
    </EditImg>
  );
};

export default EditPhoto;
