import React from "react";
import Screen2Frame from "./Screen2Frame";

const Screen2 = () => {
  return (
    <div>
      <Screen2Frame />
    </div>
  );
};

export default Screen2;
