import React, { useState } from "react";
import { Slider } from "antd";
import { GrFormSubtract } from "react-icons/gr";
import { AiOutlinePlus } from "react-icons/ai";
import { EditImg, EditModal } from "./index.style";

const EditPhoto = ({
  isModalVisible,
  setIsModalVisible,
  giftNote,
  setGiftNote,
}) => {
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible();
  };

  const handleCancel = () => {
    setIsModalVisible();
  };

  const returnSizeHeight = (size) => {
    size = size * 96;
    size /= 2.5;
    return (100 * size) / window.innerHeight;
  };

  const returnSizeWidth = (size) => {
    size = size * 96;
    size /= 2.5;
    return (100 * size) / window.innerWidth;
  };
  return (
    <EditImg>
      <EditModal
        title="Add a Gift Note"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="zoomSlider" style={{ margin: "15px 0" }}>
          <p style={{ width: "260px", margin: "auto" }}>
            We'll add this note to your package (and won't include a receipt)
          </p>
        </div>

        <div className={"noteInput"}>
          <textarea
            value={giftNote}
            onChange={(e) => setGiftNote(e.target.value)}
            placeholder="Enter Gift Note"
          ></textarea>
        </div>
        <button className="doneBtn" onClick={handleOk}>
          Submit
        </button>
      </EditModal>
    </EditImg>
  );
};

export default EditPhoto;
