import React, {useEffect, useState} from 'react';
import {Link, Navigate, Redirect, useNavigate } from 'react-router-dom'
import axios from 'axios'
import './Auth.css'

const Signup = () => {

  const navigate = useNavigate()

    const [basicInfo, setbasicInfo] = useState(true);
    const [addressInfo, setaddressInfo] = useState(false);
    const [err, setErr] = useState("");
    const [setToken, setsetToken] = useState('');

    useEffect(() => {
        setToken.length !== 0 ?
        localStorage.setItem('jwt-Token', setToken ) :
        localStorage.setItem('jwt-Token', setToken)
    })

    const [formData, setformData] = useState({
        fullname: "",
        email: "",
        password: "",
        address: "",
        address2:"",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        userType:""
    });

    const handleChange = e => {
        setformData(prevState => ({
          ...prevState,
          [e.target.name]: e.target.value,      
        }));
      };
      const handleClick = () => {

        axios.post('https://mixtilesbackend.goodsoftwaredevclients.com/api/v1/user/signupUser', {
            fullName: formData.fullname,
            email: formData.email,
            password: formData.password,
            shippingAddress: {
                address: formData.address,
                address2:  formData.address,
                city:  formData.city,
                state:  formData.state,
                zipCode:  formData.zipCode,
                country: formData.country
            },
            userType:"CUSTOMER"
        })
        .then(function (response) {
        setErr("")
        setsetToken(response.data.token);
        navigate('/upload')
        })
        .catch(function (error) {
          setErr(error.response.data.error);
        navigate('/signup')
        });
      };

    return (
        <div>
           
            <div  className="position-absolute top-50 start-50 translate-middle shadow">
            <div className='sign_up_form'>
          <Link className="position-absolute top-0 start-0 mt-2 mx-2" to="/signin" >Sign in</Link> 
           
                { basicInfo && (
                   
            <div className='mt-2 signup_form '>
              
                <h3 className='text-center mb-4'>Signup</h3>
                {
             err &&
            <p className='text-danger'>{err} </p>
            }
                
                <div className="mb-3">
                  <input type="text" name='fullname' placeholder='Full Name' onChange={handleChange} className="form-control rounded-0"/>
                </div>
                <div className="mb-3">
                  <input type="email" name='email' placeholder='Email' onChange={handleChange} className="form-control rounded-0"/>
                </div>
                <div style={{marginBottom:"90px"}}>
                  <input type="password" name='password' placeholder='Password' onChange={handleChange} className="form-control rounded-0"/>
                </div>
            <button className='btn btn-primary btn-lg mt-5 w-100 rounded-0  position-absolute bottom-0 start-50 translate-middle-x' onClick={()=>setbasicInfo(false) & setaddressInfo(true)} >next</button>
            </div>
            
                )
                }
                {
                    addressInfo && (
                        <div className='mt-2 signup_form '>
                     <h3 className='text-center mb-3' >Address Info</h3>
                     {
             err &&
            <p className='text-danger'>{err} </p>
            }
                    <button className='btn btn-outline-info mb-4' onClick={()=>setbasicInfo(true) & setaddressInfo(false)} >Go Back</button>
                     <div className="mb-3">
                       <input type="text" name='address' placeholder='Address 1' onChange={handleChange} className="form-control rounded-0"/>
                     </div>
                     <div className="mb-3">
                       <input type="email" name='address2' placeholder='Address 2' onChange={handleChange} className="form-control rounded-0"/>
                     </div>
                     <div className="mb-3">
                       <input type="" name='city' placeholder='City' onChange={handleChange} className="form-control rounded-0"/>
                     </div>
                     <div className="mb-3">
                       <input type="" name='state' placeholder='State' onChange={handleChange} className="form-control rounded-0"/>
                     </div>
                     <div className="mb-3">
                       <input type="" name='zipCode' placeholder='Zip Code' onChange={handleChange} className="form-control rounded-0"/>
                     </div>
                     <div style={{marginBottom:"80px"}}>
                       <input type="" name='country' placeholder='Country'  onChange={handleChange} className="form-control rounded-0"/>
                     </div>

                     

                     
                 <button className='btn btn-primary btn-lg mt-5 w-100 rounded-0  position-absolute bottom-0 start-50 translate-middle-x' onClick={handleClick}  >Sign up</button>
                 </div>
                )}
            </div>
            </div>
        </div>
    );
}

export default Signup;
