import styled from "styled-components";

export const FooterLinks = styled.div`
  text-align: center;
  padding: 3rem 0;
`;
export const AllLinks = styled.div`
  display: flex;
  justify-content: center;
  a {
    margin: 1.5rem 0.5rem;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14.5px;
    cursor: pointer;
    font-weight: 700;
    color: #808080;
    transition: 300ms;
    &:hover {
      color: #808080;
      text-decoration: underline;
    }
  }
`;
export const ChangeLanguageModalButton = styled.div`
  max-width: 183px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem auto;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  img {
    margin-right: 0.5rem;
  }
  &:hover {
    box-shadow: 0px 3px 4px 0px #80808050;
  }
`;
