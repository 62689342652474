import styled from "styled-components";

export const GiftCardContainer = styled.div`
  background-color: #ffffff;
  height: 100vh;

  .mainHeading {
    font-size: 48px;
    font-weight: 700;
    color: #000;
    text-align: center;
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;
export const GiftCards = styled.div`
  text-align: center;
  padding-top: 2rem;

  p {
    font-size: 18px;
    margin: 1.5rem 0;
    color: #1976d2;
    font-weight: 600;
    text-align: center;
    line-height: 22px;
  }
  img {
    min-height: 100%;
    max-width: 480px;
  }
`;
export const CardDesc = styled.div`
  padding-left: 3rem;
  padding-top: 2rem;

  @media (max-width: 768px) {
    padding-left: 1em;
  }
  h1 {
    font-size: 48px;
    font-weight: 700;
    color: #000;
    @media (max-width: 768px) {
      display: none;
    }
  }

  h4 {
    margin-top: 2.5rem;
    font-weight: 700;
    color: #000;
  }
  h6 {
    font-size: 13px;
    line-height: 26px;
    font-weight: 700;
  }

  p {
    margin: 0;
    a {
      color: #000;
    }
  }
  .ant-form {
    padding: 1rem 0 0 0;
    box-shadow: none !important;
    .ant-form-item {
      margin-bottom: 14px;
    }
    input {
      max-width: 300px;
      height: 50px;
      border-radius: 5px;
      &:hover,
      &:focus {
        // border-color: #4e4e4e;
        box-shadow: none !important;
        outline: none;
        border: 1px solid #1976d2;
      }
    }
  }
`;
export const GiftCardWrapper = styled.div`
  width: 550px;
  height: auto;
  padding: 2rem 0rem 2rem 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  overflow: auto !important;
`;
export const TileBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  box-shadow: 0px 0px 0px 1px #c5d1d4;
  border-radius: 4px;
  height: auto;
  padding: 0.5rem 1.5rem 1rem 1.5rem;
  flex-grow: 1;
  width: 130px;
  max-width: max-content;
  cursor: pointer;
  &:focus,
  &:active,
  &:hover {
    border: 2px solid #1976d2;
    box-shadow: none #c5d1d4;
    background-color: rgba(255, 25, 132, 0.05);
  }

  &.active {
    border: 2px solid #1976d2;
    box-shadow: none #c5d1d4;
    background-color: rgba(255, 25, 132, 0.05);
  }

  @media (max-width: 768px) {
    & {
    }
  }
`;

export const Amount = styled.select`
  border: 1px solid #d9d9d9;
  outline: none;
  width: 300px;
  padding: 1rem;
  border-radius: 5px;
  margin: 0 0 1rem 0;
  transition: all 0.3s;
  &:hover,
  &:focus {
    // border-color: #4e4e4e;
    box-shadow: none !important;
    outline: none;
    border: 1px solid #1976d2;
  }

  option {
    &:nth-child(1) {
      display: none;
    }
  }
`;

export const BuyBTn = styled.div`
  background-color: #1976d2;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  width: 200px;
  transition: background-color 0.2s, box-shadow 0.3s;
  height: 52px;
  margin: 1.5rem 0;
`;
export const WorkList = styled.div`
  display: flex;
  align-items: center;
  span {
    margin: 0.5rem 1rem;
    min-width: 30px;
    height: 40px;
    display: inline-block;
    svg {
      font-size: 30px;
    }
  }
`;
