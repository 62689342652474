import { Modal } from "antd";
import styled from "styled-components";

export const EditImg = styled.div`
  text-align: center;
`;
export const EditModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      left: 0 !important;
    }
    .ant-modal-header {
      text-align: center !important;
      border-bottom: none !important;
    }
    .ant-modal-body {
      padding-top: 0 !important;
      text-align: center !important;
      p {
        color: #4f4f4f;
      }
    }
    .noteInput {
      width: 300px;
      height: 250px;
      background-color: #0d6efd;
      color: #ffffff;
      padding: 15px !important;
      border-radius: 15px !important;
      margin: auto;
      margin-bottom: 30px !important;

      textarea {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        font-size: 1.4em;
        font-weight: 600;
      }

      textarea::placeholder {
        color: #ffffff;
      }
    }
    .ant-modal-footer {
      display: none;
    }
  }
  .doneBtn {
    background-color: #0d6efd;
    color: #ffffff;
    border: none;
    width: 200px;
    padding: 0.5rem;
  }
`;
