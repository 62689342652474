import { Navbar } from "react-bootstrap";
import styled from "styled-components";

export const HeaderNav = styled(Navbar)`
  background-color: #ffffff !important;

  .logolink {
    margin-left: 4rem;
  }
  .nav-link {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14.5px;
    cursor: pointer;
    font-weight: 700;
    color: #808080;
    transition: 300ms;
    &:hover {
      color: #808080;
      text-decoration: underline;
    }
  }
  img {
    width: 200px;
    background-color: #ffffff;
  }
`;
