import React, {useState, useEffect} from 'react';
import './Auth.css'
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';

const Signin = () => {

  const Nevigate = useNavigate()


    const [token, settoken] = useState('');
    
    const [err, setErr] = useState("");



    const [formData, setformData] = useState({
        email: "",
        password: "",
    });

    

    const handleChange = e => {
        setformData(prevState => ({
          ...prevState,
          [e.target.name]: e.target.value,      
        }));
      };

      const handleClick = () => {

        axios.post('https://mixtilesbackend.goodsoftwaredevclients.com/api/v1/user/loginCustomer', {
            email: formData.email,
            password: formData.password,
        })
        .then(function (response) {
        settoken(response.data.token)
        localStorage.setItem("jwt-Token",response.data.token )
        console.log(response);
        setErr("")
        Nevigate("/upload")

        })
        .catch(function (error) {
          console.log(error);
          setErr(error?.response.data.errors[0].msg);

        });
      };

    return (
        <div>
            <div className="position-absolute top-50 start-50 translate-middle shadow">
            <div className='sign_up_form'>
            <div className='mt-2 signup_form '>
                <h3 className='text-center mb-5'>Welcome To Mixtiles</h3>
                <p className='text-danger text-center' >{err}</p>
                <div className="mb-3">
                  <input placeholder='Add Email' type="email" name='email' onChange={handleChange} className="rounded-0 form-control"/>
                </div>
                <div className="" style={{marginBottom:"100px"}}>
                  <input placeholder='Password' type="password" name='password' onChange={handleChange} className="rounded-0 form-control"/>
                <p className='text-primary text-center mt-4'><Link to="/signup">Sign up</Link></p>
                </div>
            <button className='btn btn-primary btn-lg mt-5 w-100 rounded-0  position-absolute bottom-0 start-50 translate-middle-x' onClick={handleClick}  >Sign in</button>
            </div>
            </div>
            </div>
            
        </div>
    );
}

export default Signin;
