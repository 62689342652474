import React from "react";
import { Link } from "react-router-dom";

const SuccessPage = () => {
  return (
    <div>
      <div className="m-0 p-0 position-absolute top-50 start-50 translate-middle">
        <h1>Order Placed Successfully</h1>
        <Link className="btn btn-outline-primary mt-3  " to="/upload">
          Go back to home
        </Link>
      </div>
    </div>
  );
};

export default SuccessPage;
