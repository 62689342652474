import Main from "./components/Main";
import "./App.scss";
import Screen2 from "./components/Screens/Screen2/Screen2";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Signin from "./components/Screens/Auth/Signin";
import Signup from "./components/Screens/Auth/Signup";
import SuccessPage from "./components/Pages/SuccessPage";
import AddGiftCard from "./components/Pages/AddGiftCard";
import Protected from "./Protected";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "bootstrap/dist/css/bootstrap.min.css";
import GiftCard from "./components/Pages/mixtilesGiftCard/Index";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/upload" element={<Screen2 />} />
          <Route path="/checkout" element={<SuccessPage />} />
          {/* <Route path="/giftcard" element={<AddGiftCard />} /> */}
          <Route path="/giftcard" element={<GiftCard />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
