import guestInfo from "./guestInfo";
import loggedReducer from "./isLogged";
import counterReducer from "./navReducer";
import { combineReducers } from "redux";

const allReducers = combineReducers({
    guestInfoReducer: guestInfo,
    loggedReducer: loggedReducer,
    counterReducer: counterReducer
})

export default allReducers;
