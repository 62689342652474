import axios from "axios";

const apiRequest = (options) =>
  new Promise((resolve, reject) => {
    axios({
      //  baseURL: 'https://mixtiles-clone.s3.ap-south-1.amazonaws.com/api/v1',
      baseURL: "https://backend.pictings.com/api/v1",
      // baseURL: "http://localhost:5000/api/v1/",
      headers: {
        "x-auth-token": localStorage.getItem("jwt-Token"),
      },
      ...options,
    })
      // .then((response)=>{
      //   console.log(response)
      // }).catch((err)=>{
      //   console.log(err);
      // })
      .then((response) => resolve(response))
      .catch((error) => {
        if (error) {
          // error from server
          console.log("Errors from Server: ", error);
        } else {
          // error in request
          console.error("Error Message:", error);
        }
        if (error) {
          return reject("Unauthorized Person");
        }
        reject(error.response.data || error.message);
      });
  });

export default apiRequest;
